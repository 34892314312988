import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { View, foundations } from '@go1d/go1d';

import * as actions from './actions';
import CreditCard from './CreditCard/CreditCard';
import ManualPayment from './ManualPayment/ManualPayment';
import RequestPurchase from './RequestPurchase/RequestPurchase';

import { getLO, getAvailableEventSessions } from 'src/components/content/reducers';
import {
  getPaymentDetail,
  getPaymentMethod,
  getUser,
  getPortal,
  hasPermissionOnCourse
} from 'src/reducers';

/* istanbul ignore next */
function mapStateToProps(state) {
  return {
    lo: getLO(state),
    portal: getPortal(state),
    eventSessions: getAvailableEventSessions(state),
    paymentDetail: getPaymentDetail(state),
    paymentMethod: getPaymentMethod(state),
    isAdmin: hasPermissionOnCourse(state),
    user: getUser(state),
    paymentApi:'normal',
  };
}

class PaymentForm extends React.Component {
  static propTypes = {
    lo: PropTypes.objectOf(PropTypes.any).isRequired,
    eventSessions: PropTypes.arrayOf(PropTypes.any),
    isAdmin: PropTypes.bool,
    paymentDetail: PropTypes.objectOf(PropTypes.any).isRequired,
    paymentMethod: PropTypes.string.isRequired,
    paymentApi: PropTypes.oneOf(['normal']),
    portal: PropTypes.objectOf(PropTypes.any).isRequired,
    user: PropTypes.objectOf(PropTypes.any).isRequired,
    dispatch: PropTypes.func.isRequired,
    color: PropTypes.string,
    appOrigin: PropTypes.string.isRequired,
  };

  static defaultProps = {
    eventSessions: [],
    isAdmin: false,
    color: null,
    paymentApi: 'normal',
  };

  changeMethod(method) {
    this.props.dispatch(actions.changeMethod(method));
  }


  renderNav() {
    const {
      lo,
      portal,
      paymentMethod,
      paymentDetail,
    } = this.props;

    if (paymentDetail.totalAmount === 0) {
      return null;
    }

    const hasRequestPurchase = lo.type === 'course'
      && portal
      && portal.configuration
      && portal.configuration.request_purchase;

    const hasManualPayment = lo.type === 'course'
      && portal
      && portal.configuration
      && portal.configuration.manual_payment
      && (lo.data || {}).manual_payment;

    if (!hasRequestPurchase && !hasManualPayment) {
      return null;
    }

    return (
      <React.Fragment>
        <FormattedMessage
          id="app.payment.securePayment"
          defaultMessage="Secure payment"
          tagName="h3"
        />
        <Nav>
          <NavItem>
            <NavLink
              active={paymentMethod === 'creditCard'}
              onClick={() => this.changeMethod('creditCard')}
            >
              <FormattedMessage id="app.payment.creditCard" defaultMessage="Credit card" />
            </NavLink>
          </NavItem>
          {hasRequestPurchase && (
            <NavItem>
              <NavLink
                active={paymentMethod === 'requestPurchase'}
                onClick={() => this.changeMethod('requestPurchase')}
              >
                <FormattedMessage id="app.payment.requestPurchase" defaultMessage="Request purchase" />
              </NavLink>
            </NavItem>
          )}
          {hasManualPayment && (
            <NavItem>
              <NavLink
                active={paymentMethod === 'manualPayment'}
                onClick={() => this.changeMethod('manualPayment')}
              >
                <FormattedMessage id="app.payment.manualPayment" defaultMessage="Purchase order" />
              </NavLink>
            </NavItem>
          )}
        </Nav>
      </React.Fragment>
    );
  }


  renderForm() {
    const {
      appOrigin, eventSessions, isAdmin, lo, paymentDetail, paymentMethod, portal, user, color, paymentApi
    } = this.props;

    switch (paymentMethod) {
      case 'creditCard':
        return (
          <CreditCard
            lo={lo}
            payment={paymentDetail}
            portal={portal}
            user={user}
            eventSessions={eventSessions}
            isAdmin={isAdmin}
            primaryColor={color}
            appOrigin={appOrigin}
            paymentApi={paymentApi}
          />
        );
      case 'manualPayment':
        return <ManualPayment lo={lo} payment={paymentDetail} />;
      default:
        return <RequestPurchase lo={lo} portal={portal} user={user} />;
    }
  }


  render() {
    return (
      <div className="payment-form">
        <View
          backgroundColor="background"
          borderRadius={3}
          boxShadow="soft"
          css={{
            padding: foundations.spacing[5],
            [foundations.breakpoints.lg]: {
              padding: foundations.spacing[6],
            }
          }}
        >
          {this.renderNav()}
          {this.renderForm()}
        </View>
        <a href="https://stripe.com" target="_blank" className="stripe-logo" rel="noopener noreferrer">
          {" "}
        </a>
      </div>
    );
  }
}

export default connect(mapStateToProps)(PaymentForm);
